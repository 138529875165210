body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik',  'Roboto', sans-serif;
  background: linear-gradient(45deg, #333333, #2A4B7C);
  color: white;
  min-height: 100vh; /* Stellt sicher, dass der Gradient den ganzen Hintergrund abdeckt */
  width: 100%;
}

@font-face {
  font-family: 'SansArabic';
  src: local('SansArabic'), url('./fonts/SansArabic.ttf') format('truetype');
}
